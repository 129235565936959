
// Set up IIFE to update the cart item count across the site
(function() {
    const cartItemCountElement = document.getElementById('cartItemCount');

    // Function to update the cart item count
    function updateCartItemCount() {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        const totalItems = storedCart.reduce((acc, item) => acc + (item.quantity || 0), 0);
        cartItemCountElement.innerText = totalItems;
    }

    // Expose the function globally
    window.updateCartItemCount = updateCartItemCount;

    // Initialize the count on page load
    updateCartItemCount();
})();






    // Applies a style to the active link
    const currentUrl = window.location.pathname;
    const navLinks = document.querySelectorAll('.side-nav a.nav-link');
    let activeLinkFound = false;

    // Extract the slug (e.g., "Cities_and_Stories") from the current URL
    // const slugMatch = currentUrl.match(/\/(series|seriesDetail)\/([^\/]+)/);
    const slugMatch = currentUrl.match(/\/series(?:\/detail)?\/([^\/]+)/);
    let currentSlug = slugMatch ? slugMatch[1] : null;

    // Special case for the home page ('/')
    if (currentUrl === '/') {
        currentSlug = 'Cities_and_Stories';
    }

    console.log('currentSlug:', currentSlug);

    // Loop through nav links and apply active class
    navLinks.forEach(link => {
        const linkHref = link.getAttribute('href');
        
        if (linkHref) {
            const linkUrl = new URL(linkHref, window.location.origin).pathname;
            const linkSlugMatch = linkUrl.match(/\/series\/([^\/]+)/);
            const linkSlug = linkSlugMatch ? linkSlugMatch[1] : null;

            if (currentSlug && linkSlug && currentSlug === linkSlug) {
                link.setAttribute('aria-current', 'page');
                activeLinkFound = true;
            }
        }
    });

    // Handle /about and /store pages
    if (!activeLinkFound) {
        if (currentUrl === '/about') {
            const aboutLink = document.querySelector('.side-nav a.nav-link[href="/about"]');
            if (aboutLink) {
                aboutLink.setAttribute('aria-current', 'page');
            }
        } else if (currentUrl.startsWith('/store')) {
            const storeLink = document.querySelector('.side-nav a.nav-link[href="/store"]');
            if (storeLink) {
                storeLink.setAttribute('aria-current', 'page');
            }
        }
    }

    // Toggles the mobile menu
    const a = document.getElementById('A');
    const b = document.getElementById('B');
    const c = document.getElementById('C');

    const clickMenu = () => {
        b.classList.toggle('active');
    };
    const closeMenu = (event) => {
        if (!b.contains(event.target) && !a.contains(event.target) && !c.contains(event.target)) {
            b.classList.remove('active');
        }
    };

    a.addEventListener('click', clickMenu);
    c.addEventListener('click', clickMenu);
    document.addEventListener('click', closeMenu);

    // Encodes the email link on the contact page
    const encEmail = "ZGVib3JhaG1vdHRmaW5lYXJ0QGdtYWlsLmNvbQ==";
    const form = document.getElementById("contact");
    if (form) {
        form.setAttribute("href", `mailto:${atob(encEmail)}`);
    }

    // Sets up show on scroll
    const elementsToShow = document.querySelectorAll('.show-on-scroll');

    function onScroll() {
        elementsToShow.forEach(element => {
            if (isElementInViewport(element)) {
                element.classList.add('is-visible');
            } else {
                element.classList.remove('is-visible');
            }
        });
    
        requestAnimationFrame(onScroll);
    }
    
    // Call the onScroll for the first time
    requestAnimationFrame(onScroll);
    
    // Helper function to check if element is in viewport
    function isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            (rect.top <= window.innerHeight && rect.bottom >= 0)
            || (rect.bottom >= 0 && rect.top <= window.innerHeight)
        );
    }

    // Smooth scroll to top
    document.querySelectorAll('.return-scroll').forEach(element => {
        element.addEventListener('click', () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    });

